document.addEventListener('DOMContentLoaded', () => {
  var form = document.getElementById('pardot-form');

  if (form) {
    form.addEventListener('submit', (e) => {
      
      if(form.classList.contains('become-partner-form')) {
        let privacyChecked = document.querySelector('#become-a-partner-form #privacy-checkbox');
        let acknowledgementChecked = document.querySelector('#become-a-partner-form #acknowledgement-checkbox');
       
        if (privacyChecked.checked == false || acknowledgementChecked.checked == false) {
          e.preventDefault();
  
          if (privacyChecked.checked) {
            document.querySelector('#become-a-partner-form #privacy-error').classList.remove("enabled");
          } else {
            document.querySelector('#become-a-partner-form #privacy-error').classList.add("enabled");
          }

          if (acknowledgementChecked.checked) {
            document.querySelector('#become-a-partner-form #acknowledgement-error').classList.remove("enabled");
          } else {
            document.querySelector('#become-a-partner-form #acknowledgement-error').classList.add("enabled");
          }
        } else {
          document.querySelector('#become-a-partner-form #privacy-error').classList.remove("enabled");
          document.querySelector('#become-a-partner-form #acknowledgement-error').classList.remove("enabled");
        }
      }

      if(form.classList.contains('build-a-quote-form')) {

      } else {
        if (grecaptcha.getResponse().length == 0) {
          e.preventDefault();
          document.getElementById('google-recaptcha-alert').innerHTML="<p>Please fill out the CAPTCHA and try again.</p>";
        }
      }
    });
  }
});

let formCountry = document.querySelectorAll('#become-a-partner-form #pardot-form_country');

formCountry.forEach(country => {
  country.addEventListener('change', (e) => { 
      if (country.value == 'Canada') {
        document.querySelector(`#become-a-partner-form #pardot-form_state .Canadian-provinces`).classList.add('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .US-states`).classList.remove('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .no-states`).classList.add('disabled');
      } else if (country.value == 'United States') {
        document.querySelector(`#become-a-partner-form #pardot-form_state .US-states`).classList.add('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .Canadian-provinces`).classList.remove('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .no-states`).classList.add('disabled');
      } else {
        document.querySelector(`#become-a-partner-form #pardot-form_state .Canadian-provinces`).classList.remove('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .US-states`).classList.remove('enabled');
        document.querySelector(`#become-a-partner-form #pardot-form_state .no-states`).classList.remove('disabled');
      }

  });
});