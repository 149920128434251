let leaders = document.querySelectorAll('.leader');
let closeBtns = document.querySelectorAll('.close-btn');
let okBtns = document.querySelectorAll('.ok-btn');

leaders.forEach(leader => {
    leader.addEventListener('click', (e) => { 
        e.preventDefault();

        const id = leader.getAttribute('data-modal-leader');
        document.querySelector(`.modal[data-modal-id="${id}"]`).classList.add('active');

        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    });
});

closeBtns.forEach(close => {
    close.addEventListener('click', (e) => { 
        e.preventDefault();

        document.querySelector('.modal.active').classList.remove('active');
        document.querySelector(`body`).classList.remove('freeze');

        document.getElementsByTagName("body")[0].style.overflow = "auto";
    });
});

var alertMessageExists = document.getElementById("alert-message-popup");

if (alertMessageExists) {
    document.querySelector(`body`).classList.add('freeze');
}

okBtns.forEach(close => {
    close.addEventListener('click', (e) => { 
        e.preventDefault();

        document.querySelector('.modal.active').classList.remove('active');
        document.querySelector(`body`).classList.remove('freeze');
    });
});

let copyToClipboard = document.querySelectorAll('.copy-to-clipboard');

copyToClipboard.forEach(copy => {
    copy.addEventListener('click', (e) => { 
        const copyContent = copy.getAttribute('data-copy-content');
        navigator.clipboard.writeText(copyContent)
    });
});