/* get Parameters from the URL */
var getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

if (document.querySelector('.resource-advanced-filter') !== null) {
    var CurrentValues = getParams(window.location.href);

    if (CurrentValues['errorMessage']) {
        if(CurrentValues['errorMessage'].includes("Email")) {
            let emailFields = document.querySelectorAll('#pardot-form.resource-advanced-filter .email');
            
            emailFields.forEach(field => {
                field.classList.add('error');

                field.addEventListener('click', (e) => { 
                    field.classList.remove('error');
                });
            });
        }

        for(var index in CurrentValues) {
            var inputElements = document.getElementById('pardot-form_'+index);

            if (inputElements) {
                if (inputElements.type != 'submit') {
                    inputElements.value = CurrentValues[index].replace(/[\+]+/g, ' ');
                }
            }
        }
    }
}

if (document.querySelector('#pardot-form.resource-advanced-filter') !== null) {
    
    document.querySelector("#pardot-form.resource-advanced-filter").addEventListener("submit", function(e){

        var firstName = document.forms["resource-form"]["first-name"].value;
        var lastName = document.forms["resource-form"]["last-name"].value;
        var company = document.forms["resource-form"]["company"].value;
        var email = document.forms["resource-form"]["email"].value;
    
        var firstNameError = document.getElementById("first-name-error");
        var lastNameError = document.getElementById("last-name-error");
        var companyError = document.getElementById("company-error");
        var emailError = document.getElementById("email-error");
    
        firstNameError.style.display = "none";
        lastNameError.style.display = "none";
        companyError.style.display = "none";
        emailError.style.display = "none";
    
        var containsErrors = false;
    
        if (firstName.length <= 1) {
            firstNameError.innerHTML = "First Name needs to be longer than 1 character.";
            firstNameError.style.display = "block";
            containsErrors = true;
        }
    
        if (lastName.length <= 1) {
            lastNameError.innerHTML = "Last Name needs to be longer than 1 character.";
            lastNameError.style.display = "block";
            containsErrors = true;
        }
    
        if (company.length <= 1) {
            companyError.innerHTML = "Company Name needs to be longer than 1 character.";
            companyError.style.display = "block";
            containsErrors = true;
        }
    
        if (email.length <= 1) {
            emailError.innerHTML = "Email needs to be longer than 1 character.";
            emailError.style.display = "block";
            containsErrors = true;
        }
    
        if(document.getElementsByName("job-title")) {
            var jobTitleError = document.getElementById("job-title-error");
            jobTitleError.style.display = "none";
    
            if (document.forms["resource-form"]["job-title"].value.length <= 1) {
                jobTitleError.innerHTML = "Job Title needs to be longer than 1 character.";
                jobTitleError.style.display = "block";
                containsErrors = true;
            }
        }
    
        var invalidChars = ["?", "{", "[", "}", "]", "=", "*", "retire", "student", "N/A", "unknown"];
        
        for (var i = 0; i < invalidChars.length; i++) {
            if (firstName.indexOf(invalidChars[i]) != -1) {
                firstNameError.innerHTML = "First Name Field contains invalid characters.";
                firstNameError.style.display = "block";
                containsErrors = true;
            }
            
            if (lastName.indexOf(invalidChars[i]) != -1) {
                lastNameError.innerHTML = "Last Name Field contains invalid characters.";
                lastNameError.style.display = "block";
                containsErrors = true;
            }
    
            if (company.indexOf(invalidChars[i]) != -1) {
                companyError.innerHTML = "Company Field contains invalid characters.";
                companyError.style.display = "block";
                containsErrors = true;
            }
    
            if (email.indexOf(invalidChars[i]) != -1) {
                emailError.innerHTML = "Email Field contains invalid characters.";
                emailError.style.display = "block";
                containsErrors = true;
            }
    
            if(document.getElementsByName("job-title")) {
                var jobTitleError = document.getElementById("job-title-error");
                if (document.forms["resource-form"]["job-title"].value.indexOf(invalidChars[i]) != -1) {
                    jobTitleError.innerHTML = "Job Title Field contains invalid characters.";
                    jobTitleError.style.display = "block";
                    containsErrors = true;
                }
            }
        }
    
        var nameInvalidChars = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    
        for (var i = 0; i < nameInvalidChars.length; i++) {
            if (firstName.indexOf(nameInvalidChars[i]) != -1) {
                firstNameError.innerHTML = "First Name Field cannot contain numbers.";
                firstNameError.style.display = "block";
                containsErrors = true;
            }
    
            if (lastName.indexOf(nameInvalidChars[i]) != -1) {
                lastNameError.innerHTML = "Last Name Field cannot contain numbers.";
                lastNameError.style.display = "block";
                containsErrors = true;
            }
        }
    
        if (containsErrors) {
            e.preventDefault();
        }
    });
}

if (document.querySelector('.resource-advanced-filter') !== null) {
    var CurrentValues = getParams(window.location.href);

    if (CurrentValues['errorMessage']) {
        if(CurrentValues['errorMessage'].includes("Email")) {
            let emailField = document.getElementById('pardot-form_email');
            let errorField = document.getElementById('email-error');

            emailField.classList.add('error');

            emailField.addEventListener('click', (e) => { 
                field.classList.remove('error');
            });

            errorField.classList.add('enabled');
        }
    }

}

if (document.querySelector('.get-started-form') !== null) {
    var CurrentValues = getParams(window.location.href);

    if (CurrentValues['errorMessage']) {
        if(CurrentValues['errorMessage'].includes("Email")) {
            let emailField = document.getElementById('pardot-form_email');
            let errorField = document.getElementById('email-error');

            emailField.classList.add('error');

            emailField.addEventListener('click', (e) => { 
                field.classList.remove('error');
            });

            errorField.classList.add('enabled');

            if (document.getElementById('ContactForm__ErrorContainer')) {
                document.getElementById('ContactForm__ErrorContainer').classList.add('enabled');
            }
        }

        for(var index in CurrentValues) {
            var inputElements = document.getElementById('pardot-form_'+index);

            if (inputElements) {
                if (inputElements.type != 'submit') {
                    inputElements.value = CurrentValues[index].replace(/[\+]+/g, ' ');
                }
            }
        }

        document.getElementById('pardot-form').scrollIntoView({
            behavior: 'smooth'
        });
    }
}