(function() {
  var footerSectionHeaders = document.querySelectorAll('.Footer__LinksGroupHeader');

  for (var i = 0; i < footerSectionHeaders.length; i++) {
    footerSectionHeaders[i].addEventListener('click', toggleFooterSectionVisibility);
  }

  function toggleFooterSectionVisibility(e) {
    e.target.closest('.Footer__LinksGroup').classList.toggle('Footer__LinksGroup--MobExpanded');
  }
})();