(function () {
    var testimonialBlocks = document.querySelectorAll('.Testimonials');
    
    for (var i = 0; i < testimonialBlocks.length; i++) {
      var block = testimonialBlocks[i];
      var testimonials = block.getElementsByClassName('Testimonial');
      testimonials[0].classList.add('Testimonial--Active');
    }
  
    // Auto-cycle any blocks withthe data-auto-cycle attribute
    var autoCycling = document.querySelectorAll('.Testimonials[data-auto-cycle]');
    if (autoCycling.length) {
      var testimonialInterval = setInterval(autoCycle, 5000);
    }
  
    function moveToQuote(block, dir) {
      var fromEl = block.querySelector('.Testimonial--Active');
  
      if (dir === 'prev') {
        var toEl = fromEl.previousElementSibling;
        if (!toEl) {
          toEl = fromEl.parentNode.querySelector('.Testimonial:last-of-type');
        }
      } else {
        var toEl = fromEl.nextElementSibling;
        if (!toEl) {
          toEl = fromEl.parentNode.querySelector('.Testimonial:first-of-type');
        }
      }
      
      fromEl.classList.remove('Testimonial--Active');
      toEl.classList.add('Testimonial--Active');
    }
  
    function nextQuote(e) {
      clearInterval(testimonialInterval);
      var block = e.target.closest('.Testimonials__Content');
      moveToQuote(block, 'next');
    };
  
    function prevQuote(e) {
      clearInterval(testimonialInterval);
      var block = e.target.closest('.Testimonials__Content');
      moveToQuote(block, 'prev')
    };
  
    function autoCycle() {
      for (i = 0; i < autoCycling.length; i++) {
        moveToQuote(autoCycling[i], 'next');
      }
    }
  
    var prevButtons = document.querySelectorAll('.Testimonials__Arrow--Left');
    for (var i = 0; i < prevButtons.length; i++) {
      prevButtons[i].addEventListener('click', prevQuote);
    }
  
    var nextButtons = document.querySelectorAll('.Testimonials__Arrow--Right');
    for (var i = 0; i < nextButtons.length; i++) {
      nextButtons[i].addEventListener('click', nextQuote);
    }
  
  })();